import * as types from "./actionTypes";
import settingApi from "../../api/settings";

export function getLogo() {
  return async (dispatch) => {
      const result = await settingApi.getLogo();
      if(result.status){
        dispatch({ type: types.LOAD_LOGO_SUCCESS, payload: result.data });
        return { success: true, error: "", data: result.data };
      }
  };
}
