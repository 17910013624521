import * as types from "../actions/actionTypes";

const initialState = {
  logo: null
};

export default function settingReducer(state = initialState , action) {
  switch (action.type) {
    case types.LOAD_LOGO_SUCCESS:
      return {
        ...state,
        logo: action.payload
      };
   
    default:
      return state;
  }
}
