export const SET_ERROR = "SET_ERROR";
export const HIDE_ERROR = "HIDE_ERROR";

export const SET_LOADING = "SET_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILED = "AUTH_FAILED";
export const LOAD_CURRENT_USER_SUCCESS = "LOAD_CURRENT_USER_SUCCESS";
export const LOAD_LOGO_SUCCESS = "LOAD_LOGO_SUCCESS";
